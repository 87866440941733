@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

.container {
  width: 50vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-items: center;
  position: absolute;
  top: 0px;
  right: 0px;
}
.app {
  cursor:pointer;
  width: 300px;
    height:575px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position:absolute;
  left:50%;
  top:51%;
 border:none;
  transform: translate(-50%,-50%);
  z-index:-1;
  background: linear-gradient(300deg,deepskyblue,darkviolet,blue,#02e2ef,#2caee1);
 background-size: 300% 300%;
 animation: gradient-animation 30s ease infinite;
}


h1{
 width:100%;
 text-align:center;
 position:absolute;top:50%;
 left:50%;
 transform:translate(-50%,-90%);
 color:#02e2ef;
 font-family: arial;
}
header {
  padding-top: 40px; 
  border-bottom: none;
  border-radius: 8px 8px 0 0;
  position:absolute;
  top:0px;
  left:0px;
  width:100%;
  background-color:none;
}

.content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content .loader .filter {
  position: absolute;
  visibility: hidden;
}
.content .loader .dots {
  filter: url(#gooeyness);
  padding: 15px;
}
.content .loader .dot {
  background: #f1f1f1;
  border-radius: 50%;
  display: flex;
  flex-direction:row;
    align-content:center;
    align-self:center;
  width: 32px;
  height: 32px;
}
.content .loader .dot:first-child {
  animation: FirstDot 3s infinite;
}
.content .loader .dot:nth-child(2) {
  animation: SecondDot 3s infinite;
}
.content .loader .dot:nth-child(3) {
  animation: ThirdDot 3s infinite;
}
.content .loader .dot:nth-child(4) {
  animation: FourthDot 3s infinite;
}
.content .loader .dot:nth-child(5) {
  animation: FifthDot 3s infinite;
}
@keyframes FirstDot {
  0% {
    transform: scale(1) translateX(0);
 }
  25% {
    transform: scale(1.8) translateX(0);
 }
  50% {
    transform: scale(1) translateX(0);
 }
  83% {
    transform: scale(1) translateX(200px);
 }
  100% {
    transform: scale(1) translateX(0);
 }
}
@keyframes SecondDot {
  0% {
    transform: translateX(0px);
 }
  27% {
    transform: translateX(-60px);
 }
  50% {
    transform: translateX(0px);
 }
  81% {
    transform: translateX(150px);
 }
  100% {
    transform: translateX(0);
 }
}
@keyframes ThirdDot {
  0% {
    transform: translateX(0px);
 }
  29% {
    transform: translateX(-100px);
 }
  50% {
    transform: translateX(0px);
 }
  79% {
    transform: translateX(100px);
 }
  100% {
    transform: translateX(0);
 }
}
@keyframes FourthDot {
  0% {
    transform: translateX(0px);
 }
  31% {
    transform: translateX(-140px);
 }
  50% {
    transform: translateX(0px);
 }
  77% {
    transform: translateX(50px);
 }
  100% {
    transform: translateX(0);
 }
}
@keyframes FifthDot {
  0% {
    transform: scale(1) translateX(0);
 }
  33% {
    transform: scale(1) translateX(-190px);
 }
  50% {
    transform: scale(1) translateX(0);
 }
  75% {
    transform: scale(1.8) translateX(0);
 }
  100% {
    transform: scale(1) translateX(0);
 }
}
footer {
  height: 45px;
  background-color: #fff 6b;
  border-top: 1px solid #ececec;
  border-radius: 0 0 30px 30px;
}
.phone {
  display: flex;
    z-index:9;
  max-height: auto;
  width: 320px;
  
  height: 640px;
  border:none;
  position: relative;
  cursor: none;
    right:-20px;
  background: url("https://res.cloudinary.com/wikacy-com/image/upload/v1691987644/demos/galaxys9_zmzpnx.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 320px 640px;
}
.css-1hoidjo {
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  margin-bottom: 60px;
  -webkit-padding-start: 0px;
  padding-inline-start: 0px;
  -webkit-padding-end: 0px;
  padding-inline-end: 0px;
  margin-top: 0vh;
}